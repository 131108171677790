import React from 'react'
import Image from 'next/image'
import Star from '@components/Star'

export interface ITestimonial {
    imageUrl: string
    name: string
    description: string
}

interface Props {
    testimonial: ITestimonial
}

const Testimonial: React.FC<Props> = ({ testimonial }) => {
    const { imageUrl, name, description } = testimonial
    return (
        <div
            key={description}
            className="relative rounded-lg bg-slate-50 px-6 py-4 shadow-sm flex items-center space-x-3"
        >
            <Image
                className="h-10 w-10 rounded-full ring-2 ring-white top-0 left-0"
                src={imageUrl}
                alt=""
                width={50}
                height={50}
                layout="fixed"
                objectFit="fill"
            />

            <div className="flex-1 min-w-0">
                <div className="flex justify-between">
                    <p className="text-lg font-medium text-gray-900">{name}</p>
                    <div className="flex">
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                    </div>
                </div>
                <span className="text-md text-gray-600">{description}</span>
            </div>
        </div>
    )
}

export default Testimonial
