import React from 'react'
import Testimonial, { ITestimonial } from './Testimonial'

const list: ITestimonial[] = [
    {
        name: 'Narcisa O.',
        description: 'Am fost foarte multumita de serviciile oferite.',

        imageUrl: 'https://ozgrozer.github.io/100k-faces/0/2/002295.jpg',
    },
    {
        name: 'Mirela P.',
        description:
            'Va multumim pentru serviciile oferite. Am fost foarte incantata ca am primit masina la schimb pe toata perioada reparatiei.',
        imageUrl: 'https://ozgrozer.github.io/100k-faces/0/6/006725.jpg',
    },
    {
        name: 'Alexandru D.',
        description: 'Seriozitate si profesionalism!',
        imageUrl: 'https://ozgrozer.github.io/100k-faces/0/8/008114.jpg',
    },
    {
        name: 'Carmen T.',
        description:
            'Am descoperit o echipa extraordinara. Va multumesc pentru tot !!!',
        imageUrl: 'https://ozgrozer.github.io/100k-faces/0/8/008286.jpg',
    },
]

const Testimonials = () => {
    return (
        <div className="container mt-8 mx-auto px-4 max-w-7xl">
            <h2 className="t-h1">Ce spun clientii nostri</h2>
            <div className="mt-10 grid grid-cols-1 gap-4 sm:grid-cols-2">
                {list.map((t) => (
                    <Testimonial key={t.name} testimonial={t} />
                ))}
            </div>
        </div>
    )
}

export default Testimonials
